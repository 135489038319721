.footer {
    position: fixed;      /* Fissa il footer nella posizione specificata */
    bottom: 0;            /* Posiziona il footer in basso */
    left: 0;             /* Posiziona il footer a destra */
    text-align: center;
    z-index: 1000;        /* Assicurati che il footer sia sopra ad altri elementi */
    width: auto;
    max-width: 300px;     /* Imposta una larghezza massima per evitare che sia troppo grande su schermi grandi */
    margin: 40px 0 40px 50px; /* Margine per dare spazio dal bordo destro e dal bordo inferiore */
}

/* Esempio di media query per schermi di diverse dimensioni */
@media (max-width: 2000px) {
    .footer {
        margin-right: 500px;  /* Regola il margine destro per schermi più piccoli */
    }
}

@media (max-width: 768px) {
    .footer {
        display: none;
    }
}
