/* Imposta la dimensione del font base */
body {
  margin: 0;
  font-family: 'Fustat', sans-serif;
  font-size: 16px; /* Dimensione base del font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Dimensioni dei font per codice */
code {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Media Query per schermi piccoli */
@media (max-width: 768px) {
  body {
    font-size: 14px; /* Dimensione base su schermi più piccoli */
  }
}

/* Media Query per schermi ancora più piccoli */
@media (max-width: 480px) {
  body {
    font-size: 12px; /* Dimensione base su schermi molto piccoli */
  }
}

/* Impostazione della dimensione minima per i font */
html {
  font-size: calc(1rem + 0.5vw); /* Dimensione del font reattiva basata sulla viewport */
}

/* Assicurati che il testo non sia mai troppo piccolo */
@media (max-width: 600px) {
  body {
    font-size: min(16px, 4vw); /* Imposta una dimensione minima del font */
  }
}


:root {
  --primary: #2C3E50;
  --secondary: #3498DB;
  --background: #ECF0F1;
  --text: #34495E;
  --accent: #E74C3C;
  --neutral: #BDC3C7;
}

.linkTitle{
  color: gray;
}






