.bg {
  position: fixed;
  top: 20px; /* Spazio di 20px dall'alto */
  left: 20px; /* Spazio di 20px da sinistra */
  width: calc(100% - 40px); /* Larghezza ridotta per compensare 'left' e 'right' */
  height: calc(100% - 40px); /* Altezza ridotta per compensare 'top' e 'bottom' */
  z-index: -1;
  border: 2px solid white; /* Bordo bianco per la cornice */
  box-sizing: border-box;
  border-radius: 10px;
  }

  @media screen and (max-width: 768px) {
   .bg{
    
    border: 1px solid white;
   } 
  }


  