/* animate.css */
.animate {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw; /* Imposta la larghezza al 100% della viewport */
    height: 100vh; /* Imposta l'altezza al 100% della viewport */
    position: fixed; /* Fissa il contenuto per occupare l'intero schermo */
    top: 0;
    left: 0;
    z-index: 9999; /* Assicurati che il contenuto sia sopra ad altri elementi */
}

.mainText {
    color: white;
    font-size: 4rem; /* Puoi regolare la dimensione del font come preferisci */
    text-align: center;
}

.secondaryText {
    color: white;
    font-size: 1.5rem; /* Puoi regolare la dimensione del font come preferisci */
    text-align: center;
}

body{
    background-color: black;
}
