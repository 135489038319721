*{
    color: var(--neutral);
}

.mainHome{
    margin: 80px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.textHome{
    display: inline;
}

@media screen and (max-width: 768px){
    .mainHome{
        margin: 25px;
    }
}
