.moving-text-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    z-index: 99;
  }
  
  .moving-text {
    padding-left: 100%;
    animation: moveText 22s linear infinite;
  }

  .moving-text-bottom{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding-left: 100%;
    animation: moveTextBottom 22s linear infinite;
  }

  .mr10{
    margin-right: 50px;
  }
  
  @keyframes moveText {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  @keyframes moveTextBottom {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @media screen and (max-width: 768px) {
    .moving-text-container{
        display: none;
    }
  }
  