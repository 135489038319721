.background {
   color: var(--background);
   padding: 20px 50px 0 50px; 
}

.logo {
    line-height: 1;
    margin-bottom: 20px;
}

.noUnderline{
    text-decoration: none;
}

.info {
    display: flex;
    flex-direction: column;
    line-height: 2;
}

.index{
    text-decoration: none;
    display: inline;
}

.index:hover{
    text-decoration: underline;
    cursor: pointer;
    color: #ffffff;
    transition: 0.5s;
}

.inline {
 display: flex;
 flex-direction: column;

}



.clicked{
    transition: 1s;
    font-size: x-large;        
}

@media screen and (max-width: 768px) {
    .background{
        padding: 25px;
    }
}